.input-group {
  margin-bottom: 10px;
}

.control-icon {
  background: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
  transition: background ease 0.3s;
  margin-left: 10px;
}

.control-icon:hover {
  background: #e8e8e8;
}

@media (min-width: 768px) {
  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}