.layout {
  height: 100%;
  padding: 10px;
}

.sider {
  height: auto;
}

.custom-content {
  display: grid;
  grid-template-rows: 120px calc(100% - 120px);
  max-width: 100%;
}

.content-body {
  height: auto;
  width: calc(100vw - 20px);
}

.content-body > div {
  width: 100%;
}

.content-container {
  background: #fff;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, 0.03), 0 0.9375rem 1.40625rem rgba(31, 10, 6, 0.03), 0 0.25rem 0.53125rem rgba(31, 10, 6, 0.05), 0 0.125rem 0.1875rem rgba(31, 10, 6, 0.03);
  padding: 15px;
  width: 100%;
  transition: height 0.3s;
}

.form {
  padding: 0 0;
}

.ant-spin-nested-loading > div > .ant-spin {
  top: 100px;
}

@media (min-width: 1441px) {
  .content-body {
    width: calc(100vw - 300px - 30px);
  }

  .layout {
    display: grid;
    grid-template-columns: 280px calc(100% - 280px - 10px);
    grid-column-gap: 10px;
    padding: 20px;
  }

  .content {
    grid-template-rows: 90px calc(100% - 90px);
    max-width: 100%;
    height: 100%;
  }

  .content-container {
    padding: 35px;
  }

  .form {
    padding: 0 200px;
  }
}
