.create-invoice-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.widget-holder-invoice {
  margin-bottom: 20px;
}

.widget-item-invoice {
  background: #fff;
  height: 90px;
  width: 200px;
  border-radius: 10px;
  padding: 10px 10px 10px 20px;
  position: relative;
  overflow: hidden;
  text-align: left;
  margin-right: 20px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(31,10,6,0.03),0 0.9375rem 1.40625rem rgba(31,10,6,0.03),0 0.25rem 0.53125rem rgba(31,10,6,0.05),0 0.125rem 0.1875rem rgba(31,10,6,0.03);
  margin-bottom: 10px;
}

.widget-ribbon-blue, .widget-ribbon-green {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 6px;
}

.widget-ribbon-blue{
  background: #1484D6;
}

.widget-ribbon-green{
  background: #35BF46;
}


.widget-amount {
  font-size: 25px;
  color: #1484D6;
}

@media (min-width: 1200px) {
  .widget-holder-invoice {
    display: flex;
  } 
}
